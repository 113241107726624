import { Body, Container, Section, Title1 } from '@themeable/react-components'
import React, { useEffect } from 'react'

function MerchHome({setGlobals}) {

    useEffect(() => {
		setGlobals((prev) => ({
			...prev,
			page: 'shop',
			pageColor: '--color-background-flat'
		})); 
	}, [])

  return (
    <Section style={{paddingTop: '0px'}}>
        <Container>
            <Title1>
              Description of a Postpartum Doula
              </Title1>
            <Body>

In my role as a postpartum doula, I will help you adjust to bringing home a new baby. I will support the entire family and home during the first few weeks/months of your baby’s precious life. 

Services include but are not limited to: 

-**Emotional support for mom**: We will talk as little or as much as you feel comfortable. All emotions are welcome! Processing emotions is an important part of postpartum healing.  

-**Newborn care**: feeding, sleep techniques, bathing, burping, swaddling, and diapering. I will be as involved with baby as you feel comfortable. My role is to support you both (Sarah and Mike!) in bonding with your baby. I may go the whole 4 weeks never holding or doing anything for your baby, or I may hold your baby every shift so that you can take a shower or spend time as a couple/with Evelyn. It is completely your preference! 

-**Physical postpartum care for mom**: preparing your bedroom/bathroom with supplies, cleaning your room/bathroom, physically helping you in and out of bed, drawing a bath, and bringing you nourishing meals/drinks. 

-**Breastfeeding support**: breastfeeding education, helping mom and baby get into a comfortable position, advising on proper breastfeeding techniques, pumping support, nourishment during breastfeeding. I am a Certified Lactation Counselor, but if there is an issue outside of my scope, I will refer you to an IBCLC. 

-**Light housework**: dishwashing, cleaning countertops, putting away toys, washing bottles and pump parts, folding laundry, organizing the nursery, vacuuming, cleaning mom’s bathroom 

-**Meal preparation**: cooking fresh meals, warming herbal teas, picking up meals for family, preparing meals for the next day 

-**Caring for other children**: entertaining, playing outside, feeding, emotional support that is developmentally appropriate
            </Body>
        </Container>
    </Section>
  )
}

export default MerchHome
import React from 'react'
import { Section, Title1, HStack, Image, VStack, Body, Headline, Icon, Space, Media } from '@themeable/react-components'
import { Logo } from '../../Patterns/Logo'

function Hero() {
  return (
		<Section>
			<HStack gridDesktop={2} gridTablet={2} gridMobile={1}>
			
				<VStack style={{position: 'relative', minHeight: '300px'}} layout='center middle'>
					<span
						className='desktop tablet'
						style={{
						position: 'absolute',  
						left: '50%',
						top: '50%',
						width: '100%',
						height: '100%',
						transform: 'translate(-50%, -50%)',
						}}>
						<Media 
							aspectRatio='1-1'
							url='../assets/about/about-hero.png'
							profile
							wrapperStyle={{
								position: 'absolute',
								top: '50%',
    							transform: 'translateY(-50%)',
						}}

							/>
							</span>
					<VStack
						showOn='mobile'
						layout='center middle'
						style={{
						height: '300px',
						width: '100%'
						}}>
						<Media 
							aspectRatio='1-1'
							url='../assets/about/about-hero.png'
							profile
							wrapperStyle={{
								// position: 'absolute',
								// top: '50%',
    							// transform: 'translateY(-50%)',
								// maxWidth: '100%',
								// maxHeight: '100%',
								width: 'auto'
						}}

							/>
					</VStack>
					<span style={{
						opacity: '.1', 
						position: 'absolute', 
						width: '110%', 
						height: '120%', 
						animation: 'spin 800s linear infinite', 
						}}>
						<Icon
							name='PatternFlower' 
							svgPath='../Illustrations.svg'
							size='100%'
							color='--color-accent'
							/>
					</span>
				</VStack>
				<HStack>
				<Space.NewSection showOn='desktop tablet'/>
				<VStack>
					<Space.NewSection showOn='desktop tablet'/>
					<Title1 align='left' color='--color-primary'>About</Title1>
					<Body align='left'>
						Hi! I’m Jessica, a Certified Pediatric Nurse (CPN), Birth and Postpartum Doula, and mama to two boys Isaac and Micah. Certified Mom is my place to serve you with THE best mom and baby tips. I cover everything from pregnancy to postpartum and beyond.
					</Body>
					<Body>
						If you’re in Franklin, TN looking for doula support during pregnancy, birth, postpartum, and breastfeeding, you can learn more! I would LOVE to be on your team!
					</Body>
					<Space.NewSection showOn='desktop tablet'/>
				</VStack>

				</HStack>

			</HStack>
			<Space.NewSection showOn='tablet'/>
			<Space.NewSection showOn='tablet'/>
			<Space.NewSection showOn='tablet'/>
		</Section>
  )
}

export default Hero
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { Tag, Button, Group, HStack, Section, Content, Container, VStack, Space, Divider, Loader, Media, Title1, Title2, Title3, Headline, Body, Subhead, Footnote, Caption1, Caption2, Icon, Cell, Input } from '@themeable/react-components'
import Blockquote from '../Patterns/Blockquote';
import GlobalFooter from '../Patterns/GlobalFooter'


function BlogEntry({ globals, setGlobals }) {
    const[loading, setLoading] = useState(true);
    const { param } = useParams();
	const[pageTitle, setPageTitle] = useState('');
	const[blocks, setBlocks] = useState('');
	const [tags, setTags] = useState('');
	const [medical, setMedical] = useState(false);
	const [affiliate, setAffiliate] = useState(false);
	const [suggestion, setSuggestion] = useState('');
	const [suggestionSent, setSuggestionSent] = useState(false);

	useEffect(() => {
		setGlobals((prev) => ({
			...prev,
			page: '',
			pageColor: '--color-background-elevated'
		})); 
	}, [])
	
    useEffect(() => {
		fetch(`/api/blog/${param}`)
		.then((res) => res.json())
		.then((res) => {
			setPageTitle(res.Name)
			setBlocks(res.Blocks)
			setLoading(false)
			setTags(res.Tags)
			res.Disclaimers.map((disclaimer) => {
				if(disclaimer?.name === 'medical') {
					setMedical(true)
				} else if (disclaimer?.name === 'affiliate') {
					setAffiliate(true)
				}
			})
		})
		.then((res) => { 
			setGlobals((prev) => ({
				...prev,
				blogs: res
			})); 
		})
		.catch((error) => {	console.error('Error fetching data: ', error); });}, 
	[]);



	const sendSuggestion = () => {

		const currentPageUrl = window.location.href; // Get current page URL
		const message = suggestion
		fetch('/send-suggestion', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ message, currentPageUrl }),
		})
		.then((response) => {
			if (!response.ok) {
				throw new Error('Network response was not ok');
			}
			return response.json();
		})
		.then((data) => {
			setSuggestionSent(true);
			console.log('Form submitted successfully:', data);
		})
		.catch((error) => {
			console.error('Error sending form data:', error);
		});
	};


return (
    <>
	{/* Illustration */}
	<Section>
	<HStack gridDesktop={2} gridTablet={2} gridMobile={1}>
		<HStack>
			<VStack style={{position: 'relative'}} layout='top'>
				<HStack>
					<Group>
						{tags && (tags.map((tag, index) => (
							<Tag status='neutral' key={index} text1={tag} />
						)))}
					</Group>
				</HStack>
				<Title1>
					{pageTitle}
				</Title1>
				
				{medical && (
					<Container variant='blank' style={{backgroundColor: 'rgb(var(--color-primary))'}}>
						<HStack>
							<Group>
								<Icon name='Warning' color='--color-on-primary'/>
								<VStack>
									<Subhead modifier='bold' color='--color-on-primary'>Medical disclaimer:</Subhead>
									<Footnote color='--color-on-primary'>
										CertifiedMom.com, owned by Certified Mom, LLC, is not responsible or liable for any advice, course of treatment, diagnosis or any other information, services or products that you obtain through this site. You are encouraged to consult with your doctor with regard to this information contained on or through this website. You can read the full disclaimer here.
									</Footnote>
								</VStack>
							</Group>
						</HStack>
					</Container>
				)}
				{affiliate && (
					<Container variant='fill'>
						<HStack>
							<Group>
								<Icon name='Warning' />
								<VStack>
									<Subhead modifier='bold' >Affiliate disclaimer:</Subhead>
									<Footnote>
									As an Amazon Associate I earn a small commission for purchases you make through links on this page, at no additional cost to you.
									</Footnote>
								</VStack>
							</Group>
						</HStack>
					</Container>
				)}
				<Divider />
				<HStack layout='middle'>
					<Media
						profile
						url='../assets/redeeming-special-needs-profile-jessica-kounter.jpg'
						caption='Image of Jessica'
						size='medium'
						/>
					<VStack layout='top left'>
						<Group>
							<Headline>Jessica Kounter</Headline>
							<Subhead>RN, BSN, CPN  |  Pediatric Nurse & Doula</Subhead>
							<Footnote>Franklin, TN</Footnote>
						</Group>
						<Button variant='tertiary' small text1='Learn more' url='../about' />
					</VStack>
				</HStack> 

				<Divider />
				<Container variant='fill'>
				<Subhead color='--color-text-secondary'>Jump to topic:</Subhead>
				{loading ? <>
				<Button loading small variant='secondary'/>
				<Button loading small variant='secondary'/>
				</> : <>
				<VStack layout='top'>
					{blocks.map((block, index) => (
						<>
							{(() => {
								switch (block.type) {
								case 'heading_1': return ( 
									<>
										{block.heading_1.rich_text[0].plain_text.includes('##') ? (
											<>
											<Divider nested/>
											<Cell variant='link' url={`#${block.heading_1.rich_text[0].plain_text.substring(4).toLowerCase().trim().replace(/\s+/g, '-').replace(/[^a-z0-9-]/g, '')}`} >
												<Icon 
													name={block.heading_1.rich_text[0].plain_text.substring(2, 4).trim()} 
													svgPath='../Illustrations.svg'
													color='--color-accent'
													/>
												<Space.Content />
												<Cell.Text>
													<Body modifier='bold'>{block.heading_1.rich_text[0].plain_text.substring(3)}</Body>
												</Cell.Text>
											</Cell>
											</>
										) : (
											<>
											<Divider nested />
											<Cell variant='link' url={`#${block.heading_1.rich_text[0].plain_text.toLowerCase().trim().replace(/\s+/g, '-').replace(/[^a-z0-9-]/g, '')}`} >
												<Cell.Text>
													<Body modifier='bold'>{block.heading_1.rich_text[0].plain_text}</Body>
												</Cell.Text>
											</Cell>
											</>
										)}
									</>
								)
								break;
								case 'heading_2': return (  
									<>
									{block.heading_2.rich_text[0].plain_text.includes('##') ? (
										<Cell variant='link' url={`#${block.heading_2.rich_text[0].plain_text.substring(4).toLowerCase().trim().replace(/\s+/g, '-').replace(/[^a-z0-9-]/g, '')}`} >
											<Icon 
												name={block.heading_2.rich_text[0].plain_text.substring(2, 4).trim()} 
												svgPath='../Illustrations.svg'
												color='--color-accent'
												/>
											<Space.Content />
											<Cell.Text>
												<Footnote>{block.heading_2.rich_text[0].plain_text.substring(3)}</Footnote>
											</Cell.Text>
										</Cell>
									) : (
										<Cell variant='link' url={`#${block.heading_2.rich_text[0].plain_text.substring(4).toLowerCase().trim().replace(/\s+/g, '-').replace(/[^a-z0-9-]/g, '')}`} >
											<Space.Content />
											<Space.Content />
											<Cell.Text>
												<Footnote>{block.heading_2.rich_text[0].plain_text.substring(3)}</Footnote>
											</Cell.Text>
										</Cell>
									)}
								</>
								)
								break;
								default: return ''
								break;
							}
							})()}
						</>
					))}

				</VStack>
				</>}

					</Container>
				<Space.NewSection />
				
				{/* Illustration */}
				{loading ? <></>:<>	
				<Space.Grow />
				</>
				}
			</VStack>
			<HStack showOn='desktop tablet'>
				<Space.NewSection />
				<Divider vertical />
				<Space.NewSection />
			</HStack>
		</HStack>

		{/* Right column */}
		<VStack>
			<HStack showOn='mobile'><Divider /><Space.NewSection /></HStack>
		{loading ? <></> : <>
		{blocks.map((block, index) => (
			<div key={index}>
			
			{/* <Caption1>
				{JSON.stringify(block.type, null, 2)}
			</Caption1> */}
				
			{(() => {
			switch (block.type) {
			case 'paragraph': return (  
				<Body tag='p'>
				{block.paragraph.rich_text.map((chunk, index) => (
					<>
					{chunk.href ? (
						<a 
							style={{color: 'rgba(var(--color-primary))'}}
							href={chunk.href}
							target={chunk.href.includes('localhost') || chunk.href.includes('redeemingspecialneeds.com') ? undefined : '_blank'}
							rel={chunk.href.includes('localhost') || chunk.href.includes('redeemingspecialneeds.com') ? undefined : 'noopener noreferrer'}
						>
							{chunk.plain_text}
						</a>
					) : chunk.annotations?.bold ? (
						<span>
							<strong>
								{chunk.plain_text}
							</strong>
						</span>
					):chunk.annotations?.italic ? (
						<span>
							<em>
								{chunk.plain_text}
							</em>
						</span>
					):(
						<>
							<span>
								{chunk.plain_text}
							</span>
						</>
						)}
				</>
				))}
				</Body>  
			)
			break;
			case 'heading_1': return ( 
				<>
					<Space.NewSection />
					{block.heading_1.rich_text[0].plain_text.includes('##') ? (
					<span id={block.heading_1.rich_text[0].plain_text.substring(3).trim().toLowerCase().replace(/\s+/g, '-').replace(/[^a-z0-9-]/g, '')}>
					<HStack layout='middle'>
						<Icon 
						name={block.heading_1.rich_text[0].plain_text.substring(2, 4).trim()} 
						svgPath='../Illustrations.svg' 
						color='--color-accent'
						size='80px'
						/>
						<Title2 tag='h2' color='--color-accent'>
						{block.heading_1.rich_text[0].plain_text.substring(3)}
						</Title2>
					</HStack>
					</span>
					) : (
						<span id={block.heading_1.rich_text[0].plain_text.toLowerCase().replace(/\s+/g, '-').replace(/[^a-z0-9-]/g, '')}>
							<Title2 tag='h2' color='--color-accent'>
								{block.heading_1.rich_text[0].plain_text}
							</Title2>
						</span>
					)}
				</>
			)
			break;
			case 'heading_2': return (  
				<>
					<Space.NewSection />
					{block.heading_2.rich_text[0].plain_text.includes('##') ? (
					<span id={block.heading_2.rich_text[0].plain_text.substring(3).trim().toLowerCase().replace(/\s+/g, '-').replace(/[^a-z0-9-]/g, '')}>
					<HStack layout='middle'>
						<Icon 
						name={block.heading_2.rich_text[0].plain_text.substring(2, 4).trim()} 
						svgPath='../Illustrations.svg' 
						color='--color-accent'
						size='64px'
						/>
						<Title3 tag='h3' color='--color-accent'>
						{block.heading_2.rich_text[0].plain_text.substring(3)}
						</Title3>
					</HStack>
					</span>
					) : (
						<span id={block.heading_2.rich_text[0].plain_text.toLowerCase().replace(/\s+/g, '-').replace(/[^a-z0-9-]/g, '')}>
							<Title3 tag='h3' color='--color-accent'>
								{block.heading_2.rich_text[0].plain_text}
							</Title3>
						</span>
					)}
				</>
			)
			break;
			case 'heading_3': return (  
				<span id={block.heading_3.rich_text[0].plain_text.toLowerCase().replace(/\s+/g, '-').replace(/[^a-z0-9-]/g, '')}>
					<Headline tag='h4'>
						{block.heading_3.rich_text[0].plain_text}
					</Headline>
				</span>
			)
			break;
			case 'quote': return (  
					<Blockquote>
					{block.quote.rich_text[0].plain_text}
					</Blockquote>
			)
			break;
			case 'divider': return (  
				<Divider />
				)
			break;
			case 'callout': return (  
				<Container variant='fill'>
				<Section>
				<HStack>
						<Body>
						{block.callout.icon.emoji}
						</Body>
						<Body tag='h5'>
						{block.callout.rich_text[0].plain_text}
						</Body>
				</HStack>
				</Section>
				</Container>
			)
			break;
			case 'image': return (  
				<>
				<Media 
					caption={block?.image?.caption[0]?.plain_text || null}
					url={block.image.file.url}
					showCaption
				/>
				</>	
				)
			break;
			case 'numbered_list_item': return (  
				<>
				<Body>
					<span>- {block.numbered_list_item.rich_text[0].plain_text}
					</span>
				</Body>
				</>	
				)
			break;
			case 'bulleted_list_item': return (  
				<>
				<Body>
					<span>- {block.bulleted_list_item.rich_text[0].plain_text}
					</span>
				</Body>
				</>	
				)
			break;
			default: return ''
			break;
		}
	})()}
			</div>
		))}
		</>}
		</VStack>
	</HStack>
	</Section>

	<Divider extendLeft extendRight/>
	
	<Section>
		<VStack layout='center'>
			<VStack maxWidth={'600px'}>
				{!suggestionSent ? (<>
					<Group>
					<Title1 color='--color-primary' >Suggestions?</Title1>
					<Body>
						Send me ideas for future topics or if you have any feedback/questions I would love to hear from you, mama!
					</Body>
				</Group>
				<Container variant='fill'>
					<Input onChange={(e) => {setSuggestion(e.target.value)}} placeholder='What did you think❓💜'/>
				</Container>
				{suggestion && (
					<Button onClick={() => {sendSuggestion()}} text1='Send' type='button' variant='primary'/>
				)}
				</>
			):(
				<Group>
					<Title1 color='--color-primary'>Love it! 🎉</Title1>
					<Body>
						Thanks for the suggestion! I take these suggestions very seriously. Thank you for your advice!
					</Body>
				</Group>
				)}

			</VStack>
		</VStack>
	</Section>

	</>
)
}

export default BlogEntry
import React, { useEffect } from 'react';
import { Container, Group, Headline, Icon, Subhead } from '@themeable/react-components'
import Hero from './Hero'
import BirthDoulaServices from './BirthDoulaServices'
import ALaCarte from './ALaCarte'

import Outro from './Outro'

import { Divider, Section, HStack, VStack, Title3, Body, Space } from '@themeable/react-components'
import Certifications from './Certifications';


function DoulaIndex({ setGlobals }) {
    
useEffect(() => {
    setGlobals((prev) => ({
        ...prev,
        page: 'doula',
        pageColor: '--color-background-flat',
        pageTitle: 'Doula Services | Franklin, TN',
        pageDescription: "If you’re in Franklin, TN looking for doula support during pregnancy, birth, postpartum, and breastfeeding, you can learn more! I would LOVE to be on your team!"
    })); 
}, [])



    
return (<>

<Hero />

<Certifications />

<BirthDoulaServices />

<ALaCarte />



<Outro setGlobals={setGlobals} />

<Space.NewSection />

</>)}

export default DoulaIndex
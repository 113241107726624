import React from 'react'
import { Section, Title1, HStack, Image, VStack, Body, Headline, Icon, Space, Media, Group, Container } from '@themeable/react-components'
import { Logo } from '../../Patterns/Logo'

function Hero() {
  return (
		<Section>
			<Container >
				<HStack layout='middle'>
					<Group>
						<Icon name='Heart' color='--color-primary'/>
						<Headline tag='h1'>Now offering both in-person (Franklin, TN) and virtual doula support!</Headline>
					</Group>
				</HStack>
			</Container>

			<HStack gridDesktop={2} gridTablet={2} gridMobile={1}>
			
				<VStack style={{position: 'relative', minHeight: '300px'}} layout='center middle'>
					
					<VStack showOn='desktop tablet'>
						<Group>
							<Title1 align='center' style={{position: 'relative', zIndex:'2'}}>
								The <Title1 tag='span' color='--color-primary'>"Why"</Title1> Behind my Birth Doula Practice
							</Title1>
						</Group>
					</VStack>

					<VStack>
						<VStack showOn='mobile'>
							<Group>
								<Title1 align='center' style={{position: 'relative', zIndex:'2'}}>
									The <Title1 tag='span' color='--color-primary'>"Why"</Title1> Behind my Birth Doula Practice
								</Title1>
							</Group>
						</VStack>
					</VStack>
					<span style={{
						opacity: '.1', 
						position: 'absolute', 
						width: '110%', 
						height: '120%', 
						}}>
						<Icon
							name='PatternFlower' 
							svgPath='../Illustrations.svg'
							size='100%'
							color='--color-accent'
							/>
					</span>
				</VStack>
				<HStack>
				<Space.NewSection showOn='desktop tablet'/>
				<VStack>
					<Body align='left'>
						I found my way into the doula world after 7 years of working with babies, kids and families in the hospital setting. I absolutely loved my career as a pediatric nurse. It was so fulfilling and life-giving to be able to care for babies and parents in their time of need. But I found my true passion after I had my first son.
					</Body>
					<Body align='left'>
						My pregnancy, birth, and postpartum were unexpectedly challenging. After a difficult pregnancy, 4th degree tear, and breastfeeding troubles, I knew I had to do things differently the second time around.
					</Body>
					<Body align='left'>
					</Body>
					<Body align='left'>
						Fast forward 2 years later and I had the pregnancy, birth, and postpartum of my DREAMS! It was all thanks to an amazing team of women around me and some major education. Now I’m here to pass on everything I’ve learned as a pediatric nurse and a mom to you, my fellow mama.
					</Body>
					<Body align='left'>
						I cannot wait to empower you, encourage you, and care for you!
					</Body>
					<Space.NewSection showOn='desktop tablet'/>
				</VStack>

				</HStack>

			</HStack>
			<Space.NewSection showOn='tablet'/>
			<Space.NewSection showOn='tablet'/>
			<Space.NewSection showOn='tablet'/>
		</Section>
  )
}

export default Hero
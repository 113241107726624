import { Icon, VStack, HStack, Section, Headline, Subhead, Title3, Space } from '@themeable/react-components'
import React from 'react'

function Quote() {
  return (
    <>
    <Section style={{position: 'relative', width: '100%', overflow: 'hidden'}}>
        <VStack layout='center'>
            <VStack style={{position: 'absolute', width: '100%', height: '100%', left: '50%', top: '50%', transform: 'translate(-50%, -50%)'}}>
                <Icon
                    name='PatternBackground' 
                    svgPath='../Illustrations.svg'
                    color='--color-accent'
                    size='100%'
                    />
            </VStack>
            <VStack style={{maxWidth: '600px'}} layout='center' grow>
                <Space.NewSection />
                <Title3 style={{zIndex: '2'}} align='center'>
                “I want you to know there's nothing that you can’t do. You’ve already shown that with the toughest job in the world...you’re a mother. You embody what it means to be selfless and resilient. You are what the world needs.”
                </Title3>
                <Subhead>Co-Founder of Tone It Up</Subhead>
                <Headline>Katrina Scott</Headline>
                <Space.NewSection />
            </VStack>
        </VStack>
    </Section>

    {/* Illustration */}
    <VStack style={{position: 'relative'}} showOn='desktop tablet'>
        <VStack style={{position: 'absolute', top: '50%', right: '0px', transform: 'translate(-20%, -100%) rotate(0deg)'}}>
            <Icon
                name='Mom' 
                svgPath='../Illustrations.svg'
                size='200px'
                color='--color-primary'
                />
        </VStack>
    </VStack>


    </>
  )
}

export { Quote }
import React from 'react'
import { Section, Title2, Body, HStack, Button } from '@themeable/react-components'
import { setIsFormSheetActive } from '../../Patterns/Navbar'

function Outro({ setGlobals }) {


  const sayHello = () => {
    setGlobals((prev) => ({
      ...prev,
      sayHelloSheet: true
    })); 
  }

  return (
    <Section style={{maxWidth: '900px'}}>
        <Title2 align='left'>
        I am so looking forward to serving you in your pregnancy and birth!
        </Title2>

        <HStack>
            <Button variant='primary' text1='Book a Free Call' url='https://calendly.com/certifiedmom/doula-meet-and-greet?month=2024-05'/>
            <Button text1='Message Me' variant='secondary' onClick={() => {sayHello()}}/>
        </HStack>
    </Section>
  )
}

export default Outro
import { Section, VStack, HStack, Title3, Body, Space, Tab, Button, Subhead, Popover, Headline, Divider, Group, Sheet, Input, Container } from '@themeable/react-components'
import React, { useEffect, useState, useRef } from 'react'
import { useNavigate } from 'react-router-dom';
import './Navbar.css'
import { Logo, LogoShort } from './Logo';
import { collection, addDoc, Timestamp, arrayUnion, updateDoc, doc, addField } from "firebase/firestore";
import { db, auth } from '../services/firebase';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth'


function Navbar({ setGlobals, globals }) {
	const navigate = useNavigate();
	const [scrollPosition, setScrollPosition] = useState(0);
	const navbarRef = useRef('--color-positive');
	// const [isFormSheetActive, setIsFormSheetActive] = useState(false);
	const toggleFormSheet = () => {
		setGlobals((prev) => ({
			...prev,
			sayHelloSheet: !globals.sayHelloSheet
		})); 
	};
	

	const loginWithGoogle = () => {
		const user = auth.currentUser;
	  
		if (user) {
		  // User is already signed in
		  console.log("User is already signed in:", user);
		  // You may redirect or show a message indicating the user is already signed in
		  return;
		}
	  
		const provider = new GoogleAuthProvider();
	  
		signInWithPopup(auth, provider)
		  .then((result) => {
			// Handle successful sign-in
			const credential = GoogleAuthProvider.credentialFromResult(result);
			const token = credential.accessToken;
			const user = result.user;
			// ...
		  })
		  .catch((error) => {
			// Handle sign-in errors
			console.error("Error signing in:", error);
		  });
	  };


	const handleLogout = () => {
		auth.signOut()
			.then(() => {
			// Sign-out successful.
			console.log("User signed out successfully");
			})
			.catch((error) => {
			// An error happened.
			console.error("Error signing out:", error);
		});
	
	};

	// useEffect(() => {
	// 	const user = auth.currentUser
	// 	// console.log(user)
	// }, []) 

	  const [formSuccess, setFormSuccess] = useState(false)
	  const [name, setName] = useState('')
	  const [email, setEmail] = useState('')
	  const [message, setMessage] = useState('')
	  const [errorMessage, setErrorMessage] = useState('')

	
	const sendHello = () => {
		const isValidName = (name) => name && /^[a-zA-Z-' ]+$/.test(name);
		const isValidEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

		if (!isValidName(name)) {
			console.error('Invalid name');
			setErrorMessage('Invalid name');
			return;
		}

		if (!isValidEmail(email)) {
			console.error('Invalid email');
			setErrorMessage('Invalid email');
			return;
		}

		const currentPageUrl = window.location.href; // Get current page URL

		fetch('/contact-us', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ name, email, message, currentPageUrl }),
		})
		.then((response) => {
			if (!response.ok) {
				throw new Error('Network response was not ok');
			}
			return response.json();
		})
		.then((data) => {
			setFormSuccess(true);
			console.log('Form submitted successfully:', data);
		})
		.catch((error) => {
			console.error('Error sending form data:', error);
			setErrorMessage('Error sending form data');
		});
	};



	

  return (
    <>
		<nav 
		className={`navbar`}
		style={{background: `rgba(var(${globals.pageColor}), .95)`}}>
			<VStack>
				<Section style={{paddingTop: '0px', paddingBottom: '0px'}}>
					<HStack layout='' gridDesktop={2} gridTablet={2}gridMobile={3}>
						<HStack showOn='mobile'/>
						<HStack layout='left' showOn='desktop tablet'>
							<LogoShort width='44px' />
						</HStack>
						<HStack layout='center' showOn='mobile'>
							<LogoShort width='44px' />
						</HStack>

						{/* <Button type='button' text1='Log In' onClick={() => {loginWithGoogle()}}/> */}
						{/* <Button type='button' text1='Log Out' onClick={() => {handleLogout()}}/> */}

						<HStack className='navTabs' showOn='desktop tablet' layout='right'>
							<Tab 
								text1='Home' 
								small 
								onClick={()=>navigate('../')} 
								type='link' 
								indicator='bottom'
								active={ globals.page === 'home' ? true : false }/>
							<Tab 
								text1='Blog' 
								small 
								onClick={()=>navigate('../blog')}  
								type='link' 
								indicator='bottom'
								active={ globals.page === 'blog' ? true : false }/>
							<Tab 
								text1='About' 
								small 
								onClick={()=>navigate('../about')} 
								type='link' 
								indicator='bottom'
								active={ globals.page === 'about' ? true : false }/>
							{/* <Tab 
								text1='Shop' small url='../shop' type='link' indicator='bottom'
							active={ globals.page === 'shop' ? true : false }/> */}
							{/* <Tab 
								text1='Doula' small url='../doula' type='link'
								active={globals.page === 'doula'} indicator='bottom'/> */}
							<Divider vertical />
								{/* <Button variant='tertiary' small text1='Log in' onClick={() => {toggleFormSheet()}}/> */}
								<Button variant='tertiary' small text1='Say Hello' onClick={() => {toggleFormSheet()}}/>
						</HStack>
						<HStack showOn='mobile' layout='right middle'>
							<Popover trigger={
								<Button className='navHamburger' icon1='Hamburger' variant='quaternary'/>
							}>
								<Section>
									<VStack>
										<Tab 
											text1='Home'
											type='link' 
											indicator='none'
											onClick={() => navigate('../')}
											active={ globals.page === 'home' ? true : false }/>
										<Tab 
											text1='Blog' 
											onClick={() => navigate('../blog')}
											type='link' 
											indicator='none'
											active={ globals.page === 'blog' ? true : false }/>
										<Tab 
											text1='About' 
											onClick={() => navigate('../about')}
											type='link' 
											indicator='none'
											active={ globals.page === 'about' ? true : false }/>
										{/* <Tab 
											text1='Shop' url='../shop' type='link' indicator='none'
											active={ globals.page === 'shop' ? true : false }/> */}
										{/* <Tab 
											text1='Doula' url='../doula' type='link' indicator='none'
											active={ globals.page === 'doula'}/> */}
										<Divider />
										{/* <Button 
											text1='Doula' url='../doula' type='link'
											variant='primary' icon2='ArrowRight'/> */}
										<Button 
											text1='Say Hello' 
											onClick={() => {toggleFormSheet()}}
											/>
									</VStack>
								</Section>
							</Popover>
						</HStack>
						
					</HStack>
					<Divider nested/>
				</Section>
			</VStack>
		</nav>

		<Sheet variant="form" active={globals.sayHelloSheet} color='--color-background-flat' dismiss={() => {toggleFormSheet()}}>
		<Section>
			<HStack gridDesktop={3} gridMobile={3} gridTablet={3}>
				<div />
				<VStack layout='center middle'>
					<Headline>
						Say Hello
					</Headline>
				</VStack>
				<VStack layout='right'>
					<Button small variant='secondary' icon1='Close' onClick={() => {toggleFormSheet()}}/>
				</VStack>
			</HStack>
			{formSuccess ? (<>
			<Title3>
				Thanks!
			</Title3>
			<Body>
				I'm looking forward to reading your message.
			</Body>
			</>):(<>
			<Title3>
				Hi!
			</Title3>
			<Body>
			I truly can’t wait to hear from you! Hit me with your questions, comments, or suggestions here.
			</Body>
			<Space.Content />
			<Space.Content />
			<form>
				<HStack>
					<VStack>
						<Subhead style={{minWidth: '120px', marginBottom: '0px'}}>
							Name
						</Subhead>
						<Container>
							<Input onChange={((e) => {setName(e.target.value)})}/>
						</Container>
					</VStack>
					<VStack>
						<Subhead style={{minWidth: '120px', marginBottom: '0px'}}>
							Email
						</Subhead>
						<Container>
							<Input onChange={((e) => {setEmail(e.target.value)})}/>
						</Container>
					</VStack>
				</HStack>

				<VStack>
					<Subhead style={{minWidth: '120px', marginBottom: '0px'}}>
						Message
					</Subhead>
					<Container>
						<Input onChange={((e) => {setMessage(e.target.value)})}/>
					</Container>
				</VStack>
				<Subhead color='--color-negative'>{errorMessage}</Subhead>
					<Space.Content />
				<HStack layout='middle'>
					<Button 
						onClick={() => {toggleFormSheet()}} 
						text1='Cancel'
						/>
					<Button 
						type='submit' 
						onClick={((e) => {sendHello()})} 
						variant='primary'
						text1='Send'
						/>
				</HStack>
			</form>
			<Divider />
			<Button text1='Book A Call' type='link' url='https://calendly.com/certifiedmom/doula-meet-and-greet' />
			</>)}
		</Section>
	</Sheet>
    </>
  )
}

export default Navbar
import { Body, HStack, Icon, Section, Space, Title1, VStack } from '@themeable/react-components'
import React from 'react'

function Hero() {
  return (
    <VStack style={{overflow: 'hidden'}}>

			<HStack style={{position: 'relative'}} showOn='desktop tablet'>
				<div style={{position: 'absolute', transform: 'translate(-50px, -50px) rotate(15deg)', opacity: '.8'}}>
					<Icon 
						name='Baby'
						svgPath='../Illustrations.svg'
						size='350px'
						color='--color-primary'
						/>
				</div>
			</HStack>
			

			<VStack style={{
				background: `
				linear-gradient(to bottom, 
				rgba(255, 255, 255, 0.2), 
				rgba(0, 0, 0, 0.06))`}}>
				<Section style={{maxWidth: '800px'}}>

					<HStack gridDesktop={2} gridMobile={1} gridTablet={2} layout='middle'>
						<HStack style={{minWidth: '350px'}} layout='center'>
							
							<Space.NewSection showOn='desktop tablet'/>
							<HStack style={{position: 'relative'}}>

								<div style={{position: 'absolute', 
								transform: 'rotate(45deg)', 
								left: '50%', 
								top: '50%',
								transform: 'translate(-50%, -50%)',
								opacity: '.1'
							}}>
									<Icon 
										name='PatternFlower'
										svgPath='../Illustrations.svg'
										size='100%'
										color='--color-primary'
										/>
								</div>

							<Title1 align='right'>The Blog</Title1>
							</HStack>
						</HStack>
						<Body color='--color-text-secondary'>
							Hi! I’m Jessica, a Certified Pediatric Nurse (CPN) and mama to a wild toddler named Isaac. Certified Mom is my place to serve you with THE best mom and baby tips. I cover everything from pregnancy to postpartum and beyond.
						</Body>
					</HStack>
				</Section>
			</VStack>

			<HStack style={{position: 'relative'}} showOn='desktop tablet'>
				<div style={{position: 'absolute', right: '56px', bottom: '56px', transform: 'translate(30%, 30%) rotate(-25deg)',}}>
					<Icon 
						name='Breastfeeding'
						svgPath='../Illustrations.svg'
						size='300px'
						color='--color-accent'
						/>
				</div>
			</HStack>

		</VStack>
  )
}

export default Hero
import React from 'react'
import { Section, Title3, Space, HStack, VStack, Title1, Media, Body, Container, Title2 } from '@themeable/react-components'

function Family() {
return (<>
    <Section>
        <Container style={{
            background: 'linear-gradient(-45deg, rgb(var(--color-primary)), rgba(0,0,0,1))',
        }} className='dark'>
            <VStack style={{position: 'relative'}}>
                <Media 
                    url='../assets/about/certified-mom-our-family.jpg'
                    nested
                    wrapperStyle={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        opacity: '.4'
                    }}
                    />
                <Section style={{position: 'relative'}}>
                    <Space.NewSection />
                    <Space.NewSection />
                    <Title2>
                        Family
                    </Title2>
                    <Body>
                        My family is the BEST part of my life. I love these guys and my pup more than anything!
                    </Body>
                    <Space.NewSection />
                    <Space.NewSection />
                </Section>
            </VStack>
        </Container>
        <Space.NewSection />

        {/* Brad */}
        <HStack gridDesktop={2} gridMobile={1} gridTablet={2}>
            <HStack style={{}} layout='top'>
                <Media 
                    url='../assets/about/redeeming-special-needs-about-brad.jpg'
                    aspectRatio='1-1'
                    />
                <Space.NewSection showOn='desktop tablet' />
            </HStack>
            <VStack>
                <Title1 color='--color-accent'>Brad</Title1>
                <Body>
                    My beloved husband. We’ve been married for 7 years! He is the talented website designer/developer behind the screen. This ministry would not be possible without him and the gifts God has blessed him with!
                </Body>
                <Body>
                    I met Brad in college at Point Loma Nazarene University in San Diego our junior year. We were both invited to a Christmas event in downtown San Diego and met in our friend’s car on the way there. I invited him and his bff Esteban over for brownies and the rest is history! We had a love at first sight kind of romance. He pursued me sooo well. I felt wanted and treasured by Brad since the beginning. Talk about redemption from previous relationships!
                </Body>
                <Body>
                    Our marriage has really struggled through the course of having 2 special needs kiddos. You’ll hear lots about our marriage (with Brad’s permission!) and the work we put into it to keep our marriage God honoring and strong!
                </Body>
            </VStack>
        </HStack>
        <Space.NewSection />

        {/* Jes */}
        <HStack gridDesktop={2} gridMobile={1} gridTablet={2}>
            <HStack style={{}} layout='top'>
                <Media 
                    url='../assets/about/redeeming-special-needs-about-jessica.jpg'
                    aspectRatio='1-1'
                    />
                <Space.NewSection showOn='desktop tablet' />
            </HStack>
            <VStack>
                <Title1 color='--color-accent'>Jessica</Title1>
                <Body>
                    I grew up in Orange County, CA and knew I wanted to be a pediatric nurse since I was 9 years old! I’ve always loved children and almost all of my jobs growing up were with children. I went to PLNU for nursing school where I met Brad and worked as an inpatient pediatric nurse for almost 5 years in San Diego and OC. We’ve lived in southern CA, Austin, TX, and now Franklin, TN!
                <Body>
                    When I’m not loving on my babies or working as a peds nurse, I really enjoy reading and writing. And taking baths!
                </Body>
                <Body>
                    I’ve had some major health struggles and from that have become passionate about alternative health! So you’ll see some of that sprinkled in throughout the blog and podcast.                </Body>
                </Body>
            </VStack>
        </HStack>
        <Space.NewSection />

        {/* Isaac */}
        <HStack gridDesktop={2} gridMobile={1} gridTablet={2}>
            <HStack style={{}} layout='top'>
                <Media 
                    url='../assets/about/redeeming-special-needs-about-isaac.jpg'
                    aspectRatio='1-1'
                    />
                <Space.NewSection showOn='desktop tablet' />
            </HStack>
            <VStack>
                <Title1 color='--color-accent'>Isaac</Title1>
                <Body>
                    My sweet and pure 4 year old son. Isaac is cerebral-he loves to problem solve and fix things! He wants to be an astronaut or a firefighter when he grows up. I feel confident that he will make a big impact on the world with his gift of intelligence!
                <Body>
                    He also has a passion for singing, and he is so good at it! Listening to him sing is music to my ears. His precious voice is honey to the soul!
                </Body>
                <Body>
                    Isaac has a small duplication on his second chromosome, Autism, and celiac disease. With a few other medical diagnoses! I will be sharing about Isaac’s medical and behavioral challenges as the Lord leads me. I so desperately want to protect my son but also don’t want to hide who he is. He deserves to shine! God has done some insane transformation in Isaac’s life. I can’t wait to share his redemption story with you!                </Body>
                </Body>
            </VStack>
        </HStack>
        <Space.NewSection />

        {/* Micah */}
        <HStack gridDesktop={2} gridMobile={1} gridTablet={2}>
            <HStack style={{}} layout='top'>
                <Media 
                    url='../assets/about/redeeming-special-needs-about-micah.jpg'
                    aspectRatio='1-1'
                    />
                <Space.NewSection showOn='desktop tablet' />
            </HStack>
            <VStack>
                <Title1 color='--color-accent'>Micah</Title1>
                <Body>
                    My FIERCE, strong warrior. Micah has been through more in his 2 years of life than some humans go through in a lifetime! There’s a verse in Judges 6 that was written about Gideon, but also describes Micah: “And the angel of the Lord appeared and said to him, ‘The Lord is with you, O mighty man of valor.” My mighty little boy spent the first year of his life in and out of doctor’s offices, therapy centers, and the hospital. Can anyone else relate?
                </Body>
                <Body>
                    I hope you can’t! But if you can, you know the heartbreak of watching your child suffer day after day. It’s the worst!
                </Body>
                <Body>
                    Micah has similar challenges as Isaac, but more intensified. He has airway, feeding, and behavioral difficulties from his genetic disorder & Autism. I’ll dive into his full testimony on the blog/podcast, but for now I can summarize Micah like this: We faced mountains with Micah I didn’t know if we would ever summit. But God grabbed our hands, took us over, and brought us the brightest light. Micah is full of giggles and joy. He makes people smile with his own megawatt smile and long lashes! Once he trusts you, there will be no shortage of kisses and hugs. He’s minimally verbal (for now!) but will show you his love in the most creative ways!
                </Body>
                <Body>
                    Micah loves birds, fire trucks, reading books, and music. He takes such delight in each of those things!
                </Body>
            </VStack>
        </HStack>
        <Space.NewSection />

        {/* Sadie */}
        <HStack gridDesktop={2} gridMobile={1} gridTablet={2}>
            <HStack style={{}} layout='top'>
                <Media 
                    url='../assets/about/redeeming-special-needs-about-sadie.jpg'
                    aspectRatio='1-1'
                    />
                <Space.NewSection showOn='desktop tablet' />
            </HStack>
            <VStack>
                <Title1 color='--color-accent'>Sadie</Title1>
                <Body>
                    We got Sadie 2 months after we got married. Not the best decision we’ve ever made but I can’t imagine life without her! She is my go to when I’m having a bad day. Snuggling on the couch with her is the best! She is obsessed with Brad and giant tennis balls.
                </Body>
            </VStack>
        </HStack>
    </Section>
</>)
}

export default Family
import React from 'react'
import { Section, HStack, Title3, VStack, Group, Body, Space, Icon, Subhead, Headline, Caption1, Title2, Container, Caption2, Footnote } from '@themeable/react-components'

function Certifications() {
	
    const certifications = [
		{
			icon: 'Nurse',
			title: 'RN',
            subtitle: 'Board of Registered Nursing | Tennessee & California'
		},
		{
			icon: 'Science',
			title: 'BSN',
            subtitle: 'Bachelor Science Nursing | PLNU'
		},
		{
			icon: 'Certified',
			title: 'CPN',
            subtitle: 'Certified Pediatric Nurse'
		},
		{
			icon: 'Fetus',
			title: 'Certified Birth Doula',
            subtitle: 'Childbirth International'
		},
		{
			icon: 'Bottle',
			title: 'Certified Postpartum Doula',
            subtitle: 'Childbirth International'
		},
		{
			icon: 'Breastfeeding',
			title: 'Certified Lactation Consultant',
            subtitle: 'Childbirth International'
		},
	]

  return (
    <>
    <Section style={{paddingTop: '0px'}}>
        <Title2 color='--color-accent' align='center'>
            I hold the following licenses/certifications:
        </Title2> 
        <HStack gridDesktop={6} gridTablet={3} gridMobile={2}>
            {certifications.map((category) => (
                <VStack layout='left top'>
                    <Group>
                        <Container variant='fill'>
                            <Icon 
                                name={category.icon}
                                svgPath='../Illustrations.svg'
                                size='100%'
                                color='--color-primary'
                                />
                        </Container>
                        <Space.Content />
                        <Headline >{category.title}</Headline>
                        <Footnote >{category.subtitle}</Footnote >
                    </Group>
                </VStack>
            ))}
        </HStack>
    </Section>
    </>
  )
}

export default Certifications
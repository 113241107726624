// rfce
import React, { useEffect, useState } from 'react'
import { Logo } from '../../Patterns/Logo'
import { Group, Tag, Headline, Footnote, Image, Body, HStack, Title2, Title3, Title1, Icon, Section, Space, VStack, Divider, Container, Button, Media, Caption1, Cell } from '@themeable/react-components';
import { Quote } from './Quote'
import { SubscribeBanner } from '../SubscribeBanner';
import MeetTheFam from './MeetTheFam';
import { useNavigate } from 'react-router-dom';
import './home.css'


function Home({ globals, setGlobals }) {
	const navigate = useNavigate();
	const[loading, setLoading] = useState(true);

	useEffect(() => {
		setGlobals((prev) => ({
			...prev,
			page: 'home',
			pageColor: '--color-background-elevated'
		})); 
	}, [])

	useEffect(() => {
		fetch('../api/all-blogs')
		.then((res) => res.json())
		.then((res) => { 
			setGlobals((prev) => ({
				...prev,
				blogs: res
			})); 
			setLoading(false);
		})
		.then((res) => {
			console.log(globals.blogs)
		})
		.catch((error) => {	console.error('Error fetching data: ', error); });}, 
	[]);

	const siteCategories = [
		{
			url: 'Pregnancy',
			icon: 'Pregnancy',
			name: 'Pregnancy'
		},
		{
			url: 'Labor%20and%20Delivery',
			icon: 'LaborDelivery',
			name: 'Labor and Delivery'
		},
		{
			url: 'Postpartum',
			icon: 'Postpartum',
			name: 'Postpartum'
		},
		{
			url: 'Breastfeeding',
			icon: 'Breastfeeding',
			name: 'Breastfeeding'
		},
		{
			url: 'Baby',
			icon: 'Baby',
			name: 'Baby'
		},
		{
			url: 'Mom',
			icon: 'Mom',
			name: 'Mom'
		},
	]
    

  return (
    <>
	<VStack className='dark' style={{
		backgroundImage: 'url("https://res.cloudinary.com/create-new-entry/image/upload/v1590846869/certifiedmom/certified-mom-hello-hero.gif")',
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'top center',
		backgroundSize: 'cover'
		}}>
		<div style={{background: 'rgba(0,0,0,.4)'}}>

			<Section>
				<HStack gridDesktop={2} gridTablet={2} gridMobile={1} style={{zIndex: '2'}}>
					<VStack>
						<Space.NewSection />
						<Logo width={'312'}/>
						<Body>
							My name is Jessica and I’m a Certified Pediatric Nurse, Birth and Postpartum Doula, and mom to 2 of the cutest boys. I have a thing for encouraging, equipping, and educating new moms.
						</Body>
						{/* <Body>
							If you’re in Franklin, TN looking for doula support during pregnancy, birth, postpartum, and breastfeeding! I would LOVE to be on your team!
						</Body> */}
						<Space.Content />
						<HStack>
							{/* <Button 
								text1='Doula Services'
								variant='accent'
								icon2='ArrowRight'
								url='../doula'
								/> */}
							
						</HStack>
						<Space.NewSection />
					</VStack>
				</HStack>
			</Section>
		</div>
	</VStack>
	<div style={{background: 'rgb(var(--color-background-flat))' }}>
		<Section>
			<HStack gridDesktop={2} gridMobile={1} gridTablet={2} layout='middle'>
				<HStack>
				<Space.NewSection showOn='mobile'/>
				<Space.NewSection showOn='mobile'/>
					
					<VStack>
						<Space.NewSection />
						<Divider />
						<Title2 color='--color-accent'>Consider This the Online HQ for all Things Mom and Baby.</Title2>
						<Divider />
						<Space.NewSection />
					</VStack>
						<Space.NewSection />
					
				</HStack>
				<VStack>
				<Space.NewSection showOn='mobile'/>
				<Space.NewSection showOn='mobile'/>
				<Space.NewSection showOn='mobile'/>
				<Space.NewSection showOn='mobile'/>
				<Space.NewSection showOn='mobile'/>
				<Space.NewSection showOn='mobile'/>

					<div className='custom-container'>
						<Container style={{background: 'rgb(var(--color-primary))'}}>
							<Section>
								<Body>
								I pulled together everything I’ve learned on the job as a pediatric nurse and three years of motherhood to create a special space for you. Instead of going to google, you can search my site for all of the questions you feel silly for looking up. There’s no shame or judgment here, only tons of practical tips and tricks. I also added in a dash of encouragement for you mama, because you are SO deserving of being uplifted. Your job is beautiful, wonderful, messy, and also freaking hard. So take a breath, like a real inhale and exhale for 5 seconds breath, and then get your questions answered here.</Body>
							</Section>
						</Container>
					</div>
				<Space.NewSection showOn='mobile'/>
				<Space.NewSection showOn='mobile'/>
				<Space.NewSection showOn='mobile'/>
				<Space.NewSection showOn='mobile'/>
				<Space.NewSection showOn='mobile'/>
				<Space.NewSection showOn='mobile'/>
				</VStack>
			</HStack>

		</Section>
	</div>

<Space.NewSection />
	<Section>
		<Title2 align='center' color='--color-accent'>Give Me the Goods On:</Title2>
		<HStack gridDesktop={6} gridMobile={2} gridTablet={3} layout='center'>
		{siteCategories.map((category) => (
			<Button key={category.url} onClick={()=>navigate(`../blog?tag=${category.url}`)}>
				<VStack layout='center middle'>
					<Group>
						<Container variant='fill' style={{padding: '8px'}}>
							<Icon 
								name={category.icon}
								svgPath='../Illustrations.svg'
								// size='100px'
								size='120px'
								color='--color-primary'
								/>
						</Container>
						<Space.Content />
						<Headline align='center' color='--color-primary'>{category.name}</Headline>
					</Group>
				</VStack>
			</Button>
		))}
		</HStack>
	</Section>		
	
	
	<Section style={{maxWidth: '10000px'}}>
		<HStack gridDesktop={2} gridMobile={1} gridTablet={2} layout='top'>
			<HStack>
				
				<HStack showOn='desktop tablet'>
					<Space.NewSection />
					<Space.NewSection />
					<Space.NewSection />
				</HStack>
				<VStack showOn='desktop tablet'>
					<Title2 color='--color-accent'>
						My Top 3<br/>Fave Blog Posts:
					</Title2>
				</VStack>
				<VStack showOn='mobile'>
					<Title2 align='center' color='--color-accent'>
						My Top 3<br/>Fave Blog Posts:
					</Title2>
				</VStack>
				<VStack showOn='desktop tablet'>
					<Space.NewSection />
				</VStack>
			</HStack>
			<VStack layout='top'>
				{globals.blogs?.live?.slice(0, 3).map((blog, index) => (
					<>
					<Button variant='quaternary' url={`../blog/${blog.properties.URL.url}`}>
						<HStack>
							<VStack>
									<Group>
								<HStack>
										{blog.properties.Tags.multi_select.map((tag, index) => (
											<Tag status='neutral' text1={tag.name}/>
										))}
									</HStack>

										<Headline color='--color-primary'>{blog.properties.Name.title[0]?.plain_text}</Headline>
										<Footnote color='--color-text-secondary'>
											{blog.properties.Description?.rich_text[0].plain_text}...
										</Footnote>

										</Group>
							</VStack>
							<VStack style={{ position: 'relative', width: '100px !important', flexShrink: '0'}}>
								<span style={{position: 'absolute', width: '100%'}}>
									{/* <Image url={blog.properties.Cover.files[0]?.file.url} aspectRatio='4-3'/> */}
								</span>
							</VStack>
						</HStack>
					</Button>
					</>
				))}
				<Container variant='fill'>
					<Cell variant='link' url='blog'>
						<Cell.Text>
							<Headline color='--color-primary'>View all</Headline>
						</Cell.Text>
						<Icon
							name='ChevronRight'
							color='--color-primary'
							/>
					</Cell>
				</Container>
			</VStack>
		</HStack>
	</Section>
	<VStack style={{position: 'relative'}} showOn='desktop'>
		<VStack style={{position: 'absolute', transform: 'translate(24px, -50%) rotate(15deg)'}}>
			<Icon
				name='Heart' 
				svgPath='../Illustrations.svg'
				size='300px'
				color='--color-accent'
				/>
		</VStack>
	</VStack>
	<Space.NewSection />
	<Quote />
	<SubscribeBanner />
	<MeetTheFam />
    </>
  )
}

export default Home;
import React from 'react'
import { Section, HStack, Title3, VStack, Group, Body, Space, Icon, Subhead, Headline, Caption1, Title2, Container, Caption2, Footnote, Media, Divider } from '@themeable/react-components'

function PersonalLife() {

  return (
    <>
    <Section>
        <HStack layout='center'>
            <Title2 color='--color-accent' align='center' style={{maxWidth: '700px'}}>
            Now that you have some insight into my vision for Certified Mom, here’s a taste of my personal life:
            </Title2>
        </HStack>

        <Space.NewSection />

        <HStack gridDesktop={2} gridMobile={1} gridTablet={2} layout='middle'>
            <Media 
                url='https://res.cloudinary.com/create-new-entry/image/upload/v1593892120/certifiedmom/partials/certified-mom-binge-worthy-shows-nurse.jpg'
                />
                <HStack>
                    <Space.NewSection showOn='desktop tablet'/>
                    <VStack>
                        <Body>
                            When I’m not working, you can catch me in comfy clothes chasing my boys and taking full advantage of nap time.
                        </Body>
                        <Divider />
                        <HStack layout='middle'>
                            <Headline color='--color-primary' align='left' style={{minWidth: '120px'}}>
                                Nap Time =
                            </Headline>
                            <Headline color='--color-primary'>
                                Time to watch every medical drama I can get my hands on...
                            </Headline>
                        </HStack>
                        <Divider />
                        <Body>
                            I soak up every minute that I can with my sons and hunk of a husband.
                        </Body>
                    </VStack>
                </HStack>
            </HStack>

        <Space.NewSection />

        <HStack gridDesktop={2} gridMobile={1} gridTablet={2} layout='middle'>
            <HStack>

                <Body>
                    But sometimes we all need a little alone time! When I do get a minute to myself, I’m a huge fan of tone it up workouts, reading Christian romances, and going for walks with my rescue dog Sadie. I’m also super devoted to enjoying Hu chocolate or Siete churro chips every night after Isaac and Micah go to sleep.
                </Body>
                <Space.NewSection showOn='desktop tablet'/>
            </HStack>
            <Media
                url='https://res.cloudinary.com/create-new-entry/image/upload/v1593893014/certifiedmom/partials/certified-mom-jessica-kounter-ice-cream.jpg'
                />
        </HStack>
    </Section>
    <span style={{position: 'relative'}}>
        
        <Section
            style={{position: 'relative', zIndex: '1'}}>
            <Section>

            <VStack>
                <Title2 align='center'>
                    Other than Home,<br />
                    the Beach is my Happy Place
                </Title2>
                <Body align='center'>
                    While I love being home with my family, I can’t resist being outside when it’s sunny and beautiful, which is often here in Franklin, TN! The beach is my favorite outdoor spot. All of my worries melt away when I dip my toes in the water and feel the sand squish beneath my feet. Yet, the best feeling is swimming in the cool water and diving underneath the waves. There’s something so freeing about floating in an endlessly huge body of water.
                </Body>
            </VStack>
            </Section>
        </Section>

        <Media 
            url='https://res.cloudinary.com/create-new-entry/image/upload/v1593894367/certifiedmom/partials/certified-mom-beach-nurse-about.gif'
            style={{
            opacity: '.5'
        }}
        wrapperStyle={{
            background: 'rgb(var(--color-background-elevated))',
            position: 'absolute',
            height: '100%',
            top: '0px',
            left: '0px',
        }}
        />
        </span>
    <Section>
    </Section>

    </>
  )
}

export default PersonalLife
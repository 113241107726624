import React from 'react'
import { Media, Section, HStack, VStack, Title2, Body, Space, Title3, Caption1, Group, Container, Icon } from '@themeable/react-components'

function Overview() {


    const services = [
        `Free 30 minute phone call to talk about your pregnancy and hopes for birth, ask me any questions that you need to figure out if we’re a good fit`,
        `Up to 3 in-person or virtual visits during pregnancy to discuss pregnancy and childbirth education, counsel you as you make decisions, help you find the right provider, discuss comfort measures, go over any questions you have about pregnancy and birth`,
        `1 additional in-person or virtual visit during pregnancy dedicated to covering breastfeeding education, including your breast pump (if you’re not planning to breastfeed we will swap out for bottle/formula feeding education and product reviews)`,
        `Weekly text check-ins leading up to birth`,
        `Available via text/phone during the day until 37 weeks, and then on-call 24/7 until you deliver. You can text me anytime with your questions- No question is off limits here! There is no feeling crazy or weird. If you’re asking I guarantee another mama has or it’s a question that I asked myself!`,
        `Assist you in creating a birth plan`,
        `Phone support during early labor`,
        `In-person or virtual support during active labor when you’re ready for me: comfort measures, pain management, help your partner to support you, provide support directly to your partner, create a peaceful environment, encourage and empower you, changing positions, supporting you while you push, help you communicate your preferences to your team, providing nutrition throughout your birth. The list is endless!`,
        `I will stay up to 2 hours after birth to help with latching, taking pictures, getting you food, whatever you need in the moment`,
        `1 postpartum visit in-person or virtual to process your birth experience, help you with baby feeding and sleep, provide a nutritious meal`,
    ]

return (<>

    <Section>
        <Container variant='outline'>
            <Section>
                <VStack style={{position: 'absolute', transform: 'translate(24px, -50%) rotate(-15deg)', right: '80px'}}>
                    <Icon
                        name='Heart' 
                        svgPath='../Illustrations.svg'
                        size='300px'
                        color='--color-accent'
                        />
                </VStack>
                <Title2 color='--color-primary'>Birth Doula Services</Title2>
                
                {services.map((item, index) => (
                    <HStack layout='top left'>
                        <Group>

                            <Icon name='Bullet' size='28px'/>
                            <Body key={index}>{item}</Body>
                        </Group>

                    </HStack>
                ))}

            </Section>
        </Container>
    </Section>

</>)
}

export default Overview
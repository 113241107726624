import React from 'react'
import { Media, Section, HStack, VStack, Title2, Body, Space, Title3, Caption1, Group, Container, Icon, Subhead, Footnote } from '@themeable/react-components'

function Overview() {


    const services = [
        {
            title: 'Birth Plan Creation',
            description: 'Includes 1 hour virtual visit or phone call to get to know you and your birth preferences so I can guide you and type out the birth plan that’s right for you!'
        },
        {
            title: '1 Pre-natal Meeting',
            description: 'Either in-person (south Orange County, CA) or virtually. 2 hour visit to discuss childbirth preparation and education. We can also talk about your pregnancy, nutrition, exercise, finding the right provider, etc.'
        },
        {
            title: 'Text/Phone Support for the remainder of your pregnancy',
            description: 'You can call or text me anytime during the day Monday-Saturday and get your questions about pregnancy, postpartum, or breastfeeding answered!'
        },
        {
            title: '1 Pre-natal Meeting + Virtual Doula Support During Labor',
            description: '1-2 hour long pre-natal meeting to discuss your birth preferences, comfort measures, birth prep, etc. On-call 24/7 from 37 weeks until your baby’s birthday. During labor I can provide things like: emotional support, guidance for your partner, suggestions for comfort measures, guided breathing, information and advocacy for your birth team, breastfeeding support up to 2 hours postpartum.',
        },

    ]

return (<>

<VStack>

    <Section>
        <Container variant='outline'>
            <Section>
                <VStack style={{position: 'absolute', transform: 'translate(24px, -50%) rotate(-15deg)', right: '80px'}}>
                    <Icon
                        name='Baby' 
                        svgPath='../Illustrations.svg'
                        size='300px'
                        color='--color-primary'
                        />
                </VStack>
                <Title2 color='--color-accent'>A La Carte</Title2>
                <Body>
                    If you don’t feel like you need all of the above or want to personalize further, you can pick and choose which parts of the doula services you need the most and cut down on cost. For example, you might be maxed out on who is attending your birth but still want virtual doula support. So you can choose just to do 1-2 prenatal visits + text/call/email support through your pregnancy + on-call phone support during labor + 1 virtual postpartum visit (total $900).
                </Body>
                <Body>
                    We can customize it to what you need! I want my doula practice to be 100% supportive of your goals/finances/needs. Here is a list of my birth doula services laid out by price:
                </Body>
                
                {services.map((item, index) => (
                    <HStack layout='top left' key={index}>
                        <Group>

                            <Icon name='Bullet' size='28px'/>
                            <VStack>
                                <Body>{item.title}</Body>
                                <Subhead>{item.description}</Subhead>
                            </VStack>
                        </Group>

                    </HStack>
                ))}

                <Container variant='fill'>
                    <Subhead>I offer a significant discount when you combine my birth and postpartum doula services contact me for details</Subhead>
                </Container>

            </Section>
        </Container>
    </Section>
</VStack>


</>)
}

export default Overview
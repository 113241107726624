import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Group, Tag, Button, HStack, Section, Container, VStack, Space, Divider, Loader, Media, Title1, Title2, Title3, Headline, Body, Subhead, Footnote, Caption1, Caption2, Icon } from '@themeable/react-components';
import GlobalFooter from '../../Patterns/GlobalFooter'
import Hero from './Hero';

function Blog({ globals, setGlobals }) {
    const[loading, setLoading] = useState(true);
	const[filteredLayout, setFilteredLayout] = useState(false);
	const [tagNames, setTagNames] = useState([]);
	const [activeTag, setActiveTag] = useState('');

	const navigate = useNavigate();

	useEffect(() => {
		const params = new URLSearchParams(window.location.search);
        const token = params.get('tag');
        if (token) {
			console.log(token)
            setActiveTag(decodeURIComponent(token));
			fetch(`/api/filter-blogs/${decodeURIComponent(token)}`)
				.then((res) => res.json())
				.then((res) => {
					setGlobals((prev) => ({
						...prev,
						blogs: res
					})); 
					setFilteredLayout(true)
				})
		} else {
			fetch('/api/all-blogs')
				.then((res) => res.json())
				.then((res) => { 
					setGlobals((prev) => ({
						...prev,
						blogs: res
					})); 
				})
				.catch((error) => {	console.error('Error fetching data: ', error); });
		}
    }, []);


	useEffect(() => {
		setGlobals((prev) => ({
			...prev,
			page: 'blog',
			pageColor: '--color-background-flat',
			pageTitle: 'Blog | Certified Mom',
			pageDescription: "Hi! I’m Jessica, a Certified Pediatric Nurse (CPN) and mama to a wild toddler named Isaac. Certified Mom is my place to serve you with THE best mom and baby tips. I cover everything from pregnancy to postpartum and beyond."
		})); 
	}, [])



	useEffect(() => {
		if (globals.blogs != null) {
			setLoading(false);
			const tags = globals.blogs.live.flatMap(blog =>
				blog.properties.Tags.multi_select.map(tag => tag.name)
			);
			const uniqueTags = [...new Set(tags)];
			setTagNames(uniqueTags);
		}
	}, [globals.blogs]);
	

    const filterBlogsBy = useCallback((criteria) => {
		if(criteria !== undefined) {
			fetch(`/api/filter-blogs/${criteria}`)
			.then((res) => res.json())
			.then((res) => {
				setGlobals((prev) => ({
					...prev,
					blogs: res
				})); 
				navigate(`/blog?tag=${encodeURIComponent(criteria)}`)
				setFilteredLayout(true)
				setActiveTag(criteria)
			})
		} else {
			fetch('/api/all-blogs')
			.then((res) => res.json())
			.then((res) => { 
				setGlobals((prev) => ({
					...prev,
					blogs: res
				})); 
					navigate(`/blog`)
					setActiveTag('')
					setFilteredLayout(false)
				})
				.catch((error) => {	console.error('Error fetching data: ', error); });
		}
	});	
    


return (
    <>

		{/* Hero */}
		<Hero />
		<Section>
	
		{/* Topics tag/chip filter bar */}
		<HStack layout='top'>
			<HStack wrap layout='left middle'>
				<Subhead>
					Topics:
				</Subhead>
				<Group>
				{loading ? (
					<>
						<Button variant='secondary' loading small />
						<Button variant='secondary' loading small />
						<Button variant='secondary' loading small />
					</>
				) : (
					<>
						{tagNames.map((element, index) => (
							<React.Fragment key={index}>
								{activeTag === element ? (
									<Button
										key={index}
										small
										icon1='CloseCircle'
										text1={element}
										variant='primary'
										onClick={() => filterBlogsBy()}
									/>
								) : (
									<Button
										key={index}
										small
										text1={element}
										variant='secondary'
										onClick={() => filterBlogsBy(element)}
									/>
								)}
							</React.Fragment>
						))}
					</>
				)}
				</Group>
			</HStack>
		</HStack>
		<Divider />


		{filteredLayout ? (<>
			<HStack gridDesktop={6} gridTablet={5} gridMobile={2} layout='top'>
				{loading ? 
					<>
					<Loader />
					</> : <>
					{globals.blogs.live.map((blog, index) => (
					
					<Button variant='quaternary' onClick={() => navigate(`../blog/${blog.properties.URL.url}`)}>
						<VStack>
							<Group>
								<Media 
									url={blog.properties.Cover.files[0]?.file.url} 
									caption={blog.properties.Cover.files[0]?.name}
									aspectRatio='4-3'
									width='100%'
									/>
								<Subhead modifier='bold'>
									{blog.properties.Name.title[0]?.plain_text}
								</Subhead>
								<Space.Content/>
								<HStack wrap>
									{blog.properties.Tags.multi_select.map((tag, index) => (
										<Tag status='neutral' text1={tag.name}/>
									))}
								</HStack>
							</Group>
						</VStack>
					</Button>    
					))}
				</>}
			</HStack>

		</>):(<>

		{/* Featured + 3 newest blogs */}
		{loading ? 
		<>
		<Loader/>
		</> : <>
		<HStack 
			gridDesktop={2} 
			gridTablet={2}
			gridMobile={1} >

			{/* Featured */}
			<HStack layout='top'>
				<Media 
					url={globals.blogs.favorite?.properties.Cover.files[0]?.file.url} 
					alt={globals.blogs.favorite?.properties.Cover.files[0]?.name} 
					size='large' aspectRatio='1-1'/>
					<Space.Content/>
				<VStack layout='top left'>
					<Subhead color='--color-text-secondary'>Featured</Subhead>
					<Title3 color='--color-primary'>{globals.blogs.favorite?.properties.Name.title[0].plain_text}</Title3>
					<Subhead color='--color-text-secondary'>{globals.blogs.favorite?.properties.Description?.rich_text[0].plain_text}</Subhead>
					<Space.Content/>
					<Button small text1='Read now' variant='tertiary' icon2='ChevronRight' onClick={() => navigate(`../blog/${globals.blogs.favorite?.properties.URL.url}`)}/>
				</VStack>
				<HStack showOn='desktop tablet'>
					<Space.NewSection/>
				</HStack>

			</HStack>
			<VStack showOn='mobile'>
				<Space.NewSection/>
				<Divider />
				<Space.NewSection/>
			</VStack>

			{/* Top 3 newest */}
			<HStack>
				<HStack showOn='desktop tablet'>
					<Divider vertical/> 
					<Space.NewSection/>
				</HStack>
				<VStack>
				{globals.blogs.live.slice(0, 3).map((blog, index) => (
					<>
					<Button variant='quaternary' onClick={() => navigate(`../blog/${blog.properties.URL.url}`)} key={index}>
						<HStack layout='top' key={index}>
							<VStack key={index}>
								<Group key={index}>
									<HStack key={index}>
										{blog.properties.Tags.multi_select.map((tag, index) => (
											<Tag status='neutral' text1={tag.name} key={index}/>
										))}
									</HStack>
									<Headline>{blog.properties.Name.title[0]?.plain_text}</Headline>
									<Footnote color='--color-text-secondary'>
										{blog.properties.Description?.rich_text[0].plain_text}
									</Footnote>
								</Group>
							</VStack>
							<VStack>
								<Media url={blog.properties.Cover.files[0]?.file.url}  size='medium' aspectRatio='4-3'/>
							</VStack>
						</HStack>
					</Button>
					<Divider/>
					</>
				))}
				</VStack>
			</HStack>
		</HStack>
		
		</>}
		<Space.NewSection />


		<HStack gridDesktop={6} gridTablet={5} gridMobile={2} layout='top'>
			{loading ? 
			<>
			<Loader />
			</> : <>
			{globals.blogs.live.slice(3).map((blog, index) => (
			
			<Button variant='quaternary' onClick={() => navigate(`../blog/${blog.properties.URL.url}`)}>
				<VStack layout='left'>
					<Group>
						<Media 
							url={blog.properties.Cover.files[0]?.file.url} 
							caption={blog.properties.Cover.files[0]?.name}
							aspectRatio='4-3'
							wrapperStyle={{width: '100%'}}
							/>
						<Subhead modifier='bold'>
							{blog.properties.Name.title[0]?.plain_text}
						</Subhead>
						<Space.Content/>
						<HStack wrap>
								{blog.properties.Tags.multi_select.map((tag, index) => (
									<Tag status='neutral' text1={tag.name}/>
								))}
						</HStack>
					</Group>
				</VStack>
			</Button>    
			))}
		</>}
		</HStack>

		</>
		)}
		</Section>


	</>
)
}

export default Blog
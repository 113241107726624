import { Title2 } from '@themeable/react-components'
import React from 'react'
import './Blockquote.css'

function Blockquote({ children} ) {
  return (
    <div className='blockquote'>
        <Title2>
            {children}
        </Title2>
    </div>
  )
}

export default Blockquote
import { Body, Container, Section } from '@themeable/react-components'
import React, { useEffect } from 'react'

function MerchHome({setGlobals}) {

    useEffect(() => {
		setGlobals((prev) => ({
			...prev,
			page: 'shop',
			pageColor: '--color-background-flat'
		})); 
	}, [])

  return (
    <Section style={{paddingTop: '0px'}}>
        <Container>
            <Body>
                Heyo!
            </Body>
        </Container>
    </Section>
  )
}

export default MerchHome
import { Body, Container, Section } from '@themeable/react-components'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';

function MerchItem({setGlobals}) {
    const { param } = useParams();
	const [pageTitle, setPageTitle] = useState('Loading...');
	const [blocks, setBlocks] = useState({});
	const [tags, setTags] = useState('');

    useEffect(() => {
		setGlobals((prev) => ({
			...prev,
			page: '',
			pageColor: '--color-background-flat'
		})); 
	}, [])

  	useEffect(() => {
		fetch(`/api/merch/${param}`)
		.then((res) => res.json())
		.then((res) => {
			console.log('the response is: ', res)
			setPageTitle(res.Name)
			setBlocks(res.Blocks)
			setLoading(false)
			setTags(res.Tags)
			console.log('the response is: ', res)
		})
		.then((res) => { 
			setGlobals((prev) => ({
				...prev,
				blogs: res
			})); 
		})
		.catch((error) => {	console.error('Error fetching data: ', error); });}, 
	[]);

	return (
		<Section style={{paddingTop: '0px'}}>
			<Container>
				<Body>
					This is the item
				</Body>
			</Container>
		</Section>
	)
}

export default MerchItem
import React from 'react'
import { Media, Section, HStack, VStack, Title2, Body, Space, Title3, Caption1, Group, Container } from '@themeable/react-components'

function Overview() {

return (<>
<Section>

    <HStack gridDesktop={2} gridMobile={1} gridTablet={2} layout='middle'>
        <HStack>
            <VStack>
                <Title3 color='--color-primary'>Why "Certified"</Title3>
                <Body>
                    I’ve been a pediatric nurse for 4 years and I absolutely LOVE my job. As much as I enjoy it, I only get to care for 4-6 patients/families per   shift. I wanted to create this space to share my knowledge and experience with as many mamas as possible.
                </Body>
                <Body>
                    As a new mom I felt overwhelmed by all of the different approaches to parenting. I am here to reassure you that you are doing a fantastic job mama! You are more than enough for your baby, partner, and Savior. I hope you leave this space with some extra tools in your tool belt, and also feel affirmed in who YOU are as a mom and daughter of the king.                
                </Body>
              
                <Space.NewSection showOn='mobile' />
            </VStack>
            <Space.NewSection />
        </HStack>
        <Media 
            url='https://res.cloudinary.com/create-new-entry/image/upload/v1593889388/certifiedmom/partials/about-certified-mom-perdiatric-nurse.jpg'
            aspectRatio='1-1'
            />
    </HStack>
    
</Section>


</>)
}

export default Overview
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import { getStorage } from "firebase/storage"


// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyDhn0NZvDFcaF0fmqWJKVgXKkJJwQQHmWw",
    authDomain: "certifiedmom-8e351.firebaseapp.com",
    databaseURL: "https://certifiedmom-8e351.firebaseio.com",
    projectId: "certifiedmom-8e351",
    storageBucket: "certifiedmom-8e351.appspot.com",
    messagingSenderId: "499817299260",
    appId: "1:499817299260:web:9d3d51d7c525b9b8537239",
    measurementId: "G-T7LCJ0CNLW"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const functions = getFunctions(app);
export const storage = getStorage(app);
export const db = getFirestore(app);
export const auth = getAuth(app);
// export const persistLocally = setPersistence(auth, auth.Auth.Persistence.LOCAL)
export default app;